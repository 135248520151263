import {ScrollTop} from "../home/HomeElements";
import {
    ApplyFilterButton,
    RadioLabel,
    ResourcesBackground,
    ResourcesBody,
    ResourcesContianer,
    ResourcesDiv,
    ResourcesHeadText,
    ResourcesImg,
    ResourcesResult,
    ResourcesType,
    ResourcesTypeText, ResultDescription,
    ResultDivCol,
    ResultDivider,
    ResultDivRow,
    ResultRead,
    ResultText,
    ResultTypeText,
    SearchButtonMobile,
    SearchContainer,
    SearchText,
    TextResourcesHeader
} from "./ResourcesElements";
import scrollTopIcon from "../../assets/images/ScrolltoTopIcon.png";
import {DigitalHeadLogo} from "../darkivore/DarkivoreElements";
import whiteBrain from '../../assets/logos/whiteBrain.png';
import {FormControlLabel, InputAdornment, Radio, RadioGroup, TextField} from "@mui/material";
import {styled} from '@mui/material/styles';
import FooterDivider from "../../components/FooterDivider";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {resetTargetSection} from "../../redux/scrollSlice";
import {AdvisoryDivLineItemsMobile} from "../advisory/AdvisoryElement";
import {useMediaQuery} from "react-responsive";
import {Helmet} from "react-helmet";
import {useCallback} from "react";

const Resources = ({isScrolled}) => {
    const isMobile = useMediaQuery({query: `(max-width: 800px)`});

    const knowledgeBaseRef = useRef(null);
    const mediaRef = useRef(null);
    const [resourcesResultArray, setResourcesResultArray] = useState([]);
    const dispatch = useDispatch();
    const [type, setType] = useState("All");
    const [searchText, setSearchText] = useState(null);
    const targetSection = useSelector((state) => state.scroll.targetSection);

    useEffect(() => {
        if (targetSection === null) {
            window.scrollTo(0, 0);
        }
    }, [])

    const initialData = [
        {
            type: 'Research & Publications',
            title: 'Comparing Machine Learning Techniques for Malware Detection',
            link: 'https://www.scitepress.org/PublishedPapers/2020/93737',
            image: null
        },
        {
            type: 'Research & Publications',
            title: 'Investigating The Darknet',
            link: 'https://ieeexplore.ieee.org/abstract/document/9148422',
            image: null
        },
        {
            type: 'Research & Publications',
            title: 'On Darknet HoneyBots',
            link: 'https://ieeexplore.ieee.org/abstract/document/9265528',
            image: null
        },
        {
            type: 'Research & Publications',
            title: 'DDoS Attack Detection and Mitigation in SDN using Machine Learning',
            link: 'https://ieeexplore.ieee.org/abstract/document/9492558',
            image: null
        },
        {
            type: 'Datasheets',
            title: 'Tacivoar',
            link: '/documents/TacivoarDatasheet.pdf',
            image: null,
            download: 'TacivoarDatasheet'
        },
        {
            type: 'Datasheets',
            title: 'Darkivore',
            link: '/documents/DarkivoreDatasheet.pdf',
            image: null,
            download: 'DarkivoreDatasheet'
        },
        //BlogsPosts
        {
            type: 'Blog Post',
            title: 'THE MIRAGE EFFECT: EXPOSING THE ILLUSION OF CYBER THREATS',
            description: 'In the hidden corners of the Darkweb, fear often wears the mask of truth. In this Australian case, fear was the weapon. ',
            image:null,
            link: 'https://potechglobal.medium.com/australia-in-focus-c0c5c405a22c'
        }
        ,
        {
            type: 'Blog Post',
            title: 'The Wolf in Scholar’s Clothing',
            description: 'Trust is the foundation of our digital interactions. Yet, cybercriminals are exploiting this trust, disguising malicious attacks as legitimate content. ',
            image:null,
            link: 'https://potechglobal.medium.com/the-wolf-in-scholars-clothing-f1213076a52e'
        }
        ,
        {
            type: 'Blog Post',
            title: 'The Story Behind Potech’s Partnerships',
            description: 'Curious about the power of partnership? Learn how our VADs and VARs help us in delivering our solutions to the world. ',
            image:null,
            link: 'https://potechglobal.medium.com/the-story-behind-potechs-partnerships-24efe3593caf'
        }
        ,
        {
            type: 'Blog Post',
            title: 'Cybersecurity in the Age of Deep Fakes: Dealing with AI-Powered Threats',
            description: 'With AI creating hyper-realistic deep fakes, risks like financial scams and reputational damage are rapidly growing. ',
            image:null,
            link: 'https://potechglobal.medium.com/cybersecurity-in-the-age-of-deep-fakes-dealing-with-ai-powered-threats-e009b06e3bdf'
        }
        ,
        {
            type: 'Blog Post',
            title: 'Le Double Rôle de l’Intelligence Artificielle en Cybersécurité',
            description: 'L’IA est bien plus qu’un simple outil en cybersécurité, elle est désormais une nécessité dans la lutte incessante contre les menaces informatiques. Mais à quel prix ? ',
            image:null,
            link: 'https://potechglobal.medium.com/le-double-r%C3%B4le-de-lintelligence-artificielle-en-cybers%C3%A9curit%C3%A9-d0a88e83361c'
        }
        ,
        {
            type: 'Blog Post',
            title: 'Combating SOC Fatigue: How Potech Preserves its SOC Workforce',
            description: 'SOC analysts are our first line of defense—but what happens when that defense starts to crumble under the weight of alert fatigue? ',
            image:null,
            link: 'https://potechglobal.medium.com/combating-soc-fatigue-how-potech-preserves-its-soc-workforce-24227cdbf882'
        }
        ,
        {
            type: 'Blog Post',
            title: 'The Trust Trap: When Your Cloud Technology Turns into a Phishing Paradise',
            description: 'The cloud: a digital wonderland or a phishing playground? This article uncovers the shocking truth about how scammers exploit cloud platforms. ',
            image:null,
            link: 'https://potechglobal.medium.com/the-trust-trap-when-your-cloud-technology-turns-into-a-phishing-paradise-afc1cc3c1f3b'
        }
        ,
        {
            type: 'Blog Post',
            title: 'Data Protection Laws in KSA and UAE',
            description: 'Understand how the new regulations in KSA and UAE affect your business. We explain the key points of these evolving laws inspired by the GDPR. ',
            image:null,
            link: 'https://potechglobal.medium.com/data-protection-laws-in-ksa-and-uae-b9384681ece3'
        }
        ,
        {
            type: 'Blog Post',
            title: 'Into the Darkcloud: A Saga of Shadows, Crime, and Orchestrated Chaos',
            description: 'The Darkweb is no longer the final frontier of cybercrime. Welcome to the Darkcloud.',
            image:null,
            link: 'https://potechglobal.medium.com/into-the-darkcloud-a-saga-of-shadows-crime-and-orchestrated-chaos-b47a768956fc'
        }
        ,
        {
            type: 'Blog Post',
            title: 'Australia In Focus: Likes, Shares, and Lies: When Social Media Becomes a Weapon',
            description: 'Cyberbullying spiraled out of control—100+ fake accounts, leaked messages, and stolen identities. But the school didn’t stand by. They fought back with Darkivore! In just 72 hours, 98% of fake accounts disappeared.',
            image:null,
            link: 'https://potechglobal.medium.com/australia-in-focus-b10e54281c28'
        }
    ];
    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchText(query);
        const filteredData = initialData.filter(item =>
            item.title.toLowerCase().includes(query)
        );
        setData(filteredData);
    };
    useEffect(() => {
        let sectionRef;

        switch (targetSection) {
            case 'knowledge':
                sectionRef = knowledgeBaseRef;
                break;
            case 'media':
                sectionRef = mediaRef;
                break;
            default:
                sectionRef = null;
                break;
        }

        if (sectionRef && sectionRef.current) {
            sectionRef.current.scrollIntoView({behavior: 'smooth'});
            dispatch(resetTargetSection());
        }
    }, [targetSection, dispatch]);

    const CssTextField = useCallback(styled(TextField)(({theme}) => ({
        root: {
            '& label.Mui-focused': {
                color: theme.palette.common.white,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.common.white,
            },
            '& .MuiOutlinedInput-root': {
                background: `linear-gradient(90deg, ${theme.palette.primary.main} 93%, ${theme.palette.primary.dark} 100%)`,
                '& fieldset': {
                    borderColor: theme.palette.common.white,
                    borderRadius: 5,
                    color: theme.palette.common.white,
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    padding: 0,
                },
                '& input': {
                    padding: '10px 14px',
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.common.white,
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.common.white,
                },
            },
        },
    })), []);


    const CssRadioField = styled(Radio)(({theme}) => ({
        root: {
            '& .MuiSvgIcon-root': {
                fontSize: 20,
                color: theme.palette.common.black,
            },
            color: theme.palette.common.black,
        },
        checked: { // Use the pseudo-class for checked state
            color: theme.palette.common.black,
        },
        '& .MuiButtonBase-root': { // Target the button base style
            paddingTop: theme.spacing(3), // Use theme spacing for consistency
            paddingBottom: theme.spacing(3),
        },
    }));

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(initialData);
    }, [])

    const ApplyFilter = () => {
        if (type.includes('All'))
            setData(initialData);
        else {
            const filteredData = initialData.filter((d) => type.includes(d.type));
            setData(filteredData);
        }
    }

    useEffect(() => {
        const ResourcesResultArray = [];
        data.forEach(d => {
            const element = <>
                <ResultDivRow>
                    <ResultDivCol>
                        <ResultTypeText>
                            {d.type}
                        </ResultTypeText>
                        <ResultText>
                            {d.title}
                        </ResultText>
                        {
                            d?.description &&
                            <ResultDescription>{d.description}</ResultDescription>
                        }
                        {/* download={d.download} */}
                        <ResultRead href={d.link} target="_blank" rel="nofollow">
                            Read
                        </ResultRead>
                    </ResultDivCol>
                    {/* <ResultImg src={d.image ?? resultImg} /> */}
                </ResultDivRow>
                <ResultDivider/>
            </>
            ResourcesResultArray.push(element);
        });
        setResourcesResultArray(ResourcesResultArray)
    }, [data])

    const onOptionChange = e => {
        setType(e.target.value)
    }

    return <>
        <Helmet>
            <title>Resources: POTECH’s publications and product datasheets</title>
            <meta name="robots" content="nofollow"></meta>
            <meta name="description"
                  content="A closer look at POTECH’s cybersecurity-related publications and product datasheets."/>
        </Helmet>
        <ResourcesBackground ref={knowledgeBaseRef}>
            <ResourcesImg/>
            {/*<TextResourcesHeader>*/}
            {/*    Resources*/}
            {/*</TextResourcesHeader>*/}
            <ResourcesDiv>
                <DigitalHeadLogo src={whiteBrain}/>
                <ResourcesHeadText>
                    Resources
                </ResourcesHeadText>
                <ResourcesBody>
                    {/* search bar */}
                    <SearchContainer className="search">
                        <CssTextField
                            sx={{
                                input: {
                                    backgroundColor: 'white',
                                    borderTopLeftRadius: 5,
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: isMobile ? 5 : 0,
                                    borderTopRightRadius: isMobile ? 5 : 0
                                }
                            }}
                            value={searchText}
                            onChange={handleSearch}
                            fullWidth
                            placeholder="Search by Keywords"
                            InputProps={{
                                endAdornment: (!isMobile &&
                                    <InputAdornment position="end" style={{marginLeft: 12}}>
                                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M26.6209 24.7238L21.6154 19.755C23.5583 17.3305 24.4993 14.2532 24.2447 11.1558C23.9901 8.0584 22.5594 5.17633 20.2466 3.1022C17.9339 1.02807 14.915 -0.080459 11.8106 0.00455155C8.70629 0.0895621 5.75247 1.36165 3.55654 3.55924C1.36062 5.75684 0.0894941 8.71291 0.0045481 11.8196C-0.080398 14.9263 1.02729 17.9475 3.09984 20.262C5.1724 22.5765 8.05229 24.0083 11.1473 24.2631C14.2424 24.5179 17.3174 23.5762 19.74 21.6318L24.705 26.6006C24.8305 26.7272 24.9797 26.8276 25.1441 26.8962C25.3085 26.9647 25.4848 27 25.663 27C25.8411 27 26.0174 26.9647 26.1818 26.8962C26.3462 26.8276 26.4955 26.7272 26.6209 26.6006C26.8641 26.3488 27 26.0124 27 25.6622C27 25.312 26.8641 24.9756 26.6209 24.7238ZM12.171 21.6318C10.3031 21.6318 8.47708 21.0775 6.92396 20.0389C5.37084 19.0004 4.16033 17.5242 3.44551 15.7972C2.73068 14.0701 2.54365 12.1697 2.90807 10.3363C3.27248 8.5029 4.17197 6.81879 5.49279 5.49696C6.81361 4.17514 8.49644 3.27497 10.3285 2.91027C12.1605 2.54558 14.0595 2.73276 15.7852 3.44812C17.5109 4.16349 18.9859 5.37492 20.0237 6.92922C21.0615 8.48352 21.6154 10.3109 21.6154 12.1802C21.6154 14.6869 20.6203 17.091 18.8492 18.8635C17.078 20.636 14.6758 21.6318 12.171 21.6318Z"
                                                fill="white"/>
                                        </svg>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </SearchContainer>
                    <SearchButtonMobile>
                        <SearchText>
                            Search
                        </SearchText>
                    </SearchButtonMobile>
                    <ResourcesContianer>
                        <ResourcesType>
                            <ResourcesTypeText>
                                Type
                            </ResourcesTypeText>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="1"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="Research & Publications"
                                                  control={<CssRadioField
                                                      checked={type.includes('Research')}
                                                      onChange={onOptionChange}
                                                  />}
                                                  label={<RadioLabel>Research & Publications</RadioLabel>}/>
                                <AdvisoryDivLineItemsMobile marginTop={'1vh'}/>
                                <FormControlLabel value="Datasheets"
                                                  control={<CssRadioField
                                                      checked={type.includes('Data')}
                                                      onChange={onOptionChange}
                                                  />}
                                                  label={<RadioLabel>Datasheets</RadioLabel>}/>
                                <AdvisoryDivLineItemsMobile marginTop={'1vh'}/>
                                <FormControlLabel value="Blog Posts"
                                                  control={<CssRadioField
                                                      checked={type.includes('Blog Post')}
                                                      onChange={onOptionChange}
                                                  />}
                                                  label={<RadioLabel>Blog Posts</RadioLabel>}/>
                                {/* <AdvisoryDivLineItemsMobile marginTop={'1vh'} />
                                <FormControlLabel value="3" control={<CssRadioField />} label={<RadioLabel>Blog Posts</RadioLabel>} /> */}
                                <AdvisoryDivLineItemsMobile marginTop={'1vh'}/>
                                <FormControlLabel value="All"
                                                  control={<CssRadioField
                                                      checked={type.includes('All')}
                                                      onChange={onOptionChange}
                                                  />}
                                                  label={<RadioLabel>All</RadioLabel>}/>
                                <AdvisoryDivLineItemsMobile marginTop={'3vh'}/>
                            </RadioGroup>
                            <ApplyFilterButton onClick={ApplyFilter}>
                                APPLY FILTER
                            </ApplyFilterButton>
                        </ResourcesType>
                        <ResourcesResult>
                            <ResourcesTypeText>
                                Results
                            </ResourcesTypeText>
                            {/* list of results */}
                            {resourcesResultArray}
                            {/* end of list */}
                        </ResourcesResult>
                    </ResourcesContianer>
                </ResourcesBody>
            </ResourcesDiv>
            <ScrollTop isScrolled={isScrolled} src={scrollTopIcon} onClick={() => {
                window.scrollTo(0, 0)
            }}/>
        </ResourcesBackground>
        {}
        <FooterDivider/>
    </>
}

export default Resources;
