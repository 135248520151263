import {
    Wrapper, Head, Btn, Intro, YT, DarkAgentFeatures, DarkAgentServices
} from "./theDarkAgent.Elements";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterDivider from "../../components/FooterDivider";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {Helmet} from "react-helmet";
import {useCallback} from "react";
import DarkAgentLogo from '../../assets/images/dark-agent.png';
import DarkAgentThumbnail from '../../assets/images/dark-agent-thumbnail.png';
import DarkAgentF1 from '../../assets/images/dark-agent-f1.png';
import DarkAgentF2 from '../../assets/images/dark-agent-f2.png';
import DarkAgentF3 from '../../assets/images/dark-agent-f3.png';
import DarkAgentPlayIcon from '../../assets/images/dark-agent-play-icon.svg';
import DarkAgentMaximize from '../../assets/images/dark-agent-maximize.png';
import DarkAgentOptimize from '../../assets/images/dark-agent-optimize.png';
import DarkAgentSanitize from '../../assets/images/dark-agent-sanitize.png';
import {useNavigate} from "react-router-dom";

const TheDarkAgent = ({isScrolled}) => {
    const isMobile = useMediaQuery({query: `(max-width: 800px)`});
    const [playVideo, setPlayVideo] = useState(false);
    const targetSection = useSelector((state) => state.scroll.targetSection);
    const navigate = useNavigate();

    useEffect(() => {
        if (targetSection === null) {
            window.scrollTo(0, 0);
        }
    }, [])

    return <>
        <Helmet>
            <title>The Dark Agent</title>
            <meta name="robots" content="nofollow"></meta>
            <meta name="description"
                  content="Your Ultimate Digital Risk and Threat Intel Ally"/>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet"
                  integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
                  crossOrigin="anonymous"/>
        </Helmet>
        <Wrapper>
            <Head>
                <h1>DARKIVORE UNLEACHES ITS NEW AI ASSISTANT</h1>
                <img alt={''} src={DarkAgentLogo}/>
                <h1>Your Ultimate Digital Risk and <br/>Threat Intel Ally</h1>
                <Btn onClick={() => navigate('/contact-us')}>REQUEST DEMO</Btn>
            </Head>
            <Intro>
                Dark Agent addresses the critical challenges faced by security professionals dealing with the
                overwhelming volume and complexity of threat data. By automating key processes, delivering actionable
                insights, and conducting takedowns on user’s behalf, the Dark Agent significantly reduces the time and
                effort required to neutralize threats.
            </Intro>
            <YT className={playVideo ? 'video-playing' : ''}>
                {
                    !playVideo &&
                    <>
                        <img src={DarkAgentThumbnail} alt={''} className={'bg-img img-fluid'}/>
                        <img onClick={() => setPlayVideo(true)} src={DarkAgentPlayIcon} alt={'play'}
                             className={'play-icon'}/>
                    </>
                }

                {
                    playVideo &&
                    <div className={'iframe-holder'}>
                        <iframe width={'100%'} height={'100%'}
                                src="https://www.youtube.com/embed/Q7xutaYMv94?autoplay=1"
                                title="Dummy Video For Website" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                }
            </YT>
            <DarkAgentFeatures>
                <Row>
                    <Col md={6} sm={12}>
                        <h2>Maximize Takedowns</h2>
                        <p>Streamlines the takedown process by automating the selection of relevant targets, generating
                            detailed investigation reports, and launching the takedown. </p>
                        <img alt={''} src={DarkAgentMaximize} className={'img-fluid w-50'}/>
                    </Col>
                    <Col md={6} sm={12}><img className={'img-fluid'} alt={''} src={DarkAgentF1}/></Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <h2>Optimize Reports</h2>
                        <p>Provides contextual answers related to the customer’s latest threats, dramatically
                            accelerating data visibility and enabling faster decision-making. </p>
                        <img alt={''} src={DarkAgentOptimize} className={'img-fluid w-75'}/>
                    </Col>
                    <Col md={6} sm={12}><img className={'img-fluid'} alt={''} src={DarkAgentF2}/></Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <h2>Sanitize The Noise</h2>
                        <p>Effectively filters out false positives and irrelevant data, reducing the burden of sifting
                            through massive datasets and allowing security teams to focus on genuine threats.</p>
                        <img alt={''} src={DarkAgentSanitize} className={'img-fluid w-100'}/>
                    </Col>
                    <Col md={6} sm={12}><img className={'img-fluid'} alt={''} src={DarkAgentF3}/></Col>
                </Row>
            </DarkAgentFeatures>
            <DarkAgentServices>
                <Row>
                    <Col md={6}>
                        <h2>Edge-Based<br/>Security</h2>
                        <p>Operates on an edge-based architecture, prioritizing super-secure, zero-data access for
                            customer
                            privacy. Dark Agent does not have access to customer data, ensuring maximum
                            confidentiality.</p>
                    </Col>
                    <Col md={6}>
                        <h2>Multi-Lingual<br/>Support</h2>
                        <p>Facilitates seamless communication with Darkivore’s core engine, regardless of language
                            barriers.(English, French, Spanish and Arabic in the first pilot).</p>
                    </Col>
                </Row>
            </DarkAgentServices>
        </Wrapper>
        <FooterDivider/>
    </>
}

export default TheDarkAgent;
