import styled from "styled-components";
import ResourcesBack from '../../assets/images/ResourcesBack.png';

export const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    padding-top:300px;
    text-align: center;
    
    @media screen and (max-width: 800px) {
        height: auto;
    }
`;

export const Btn = styled.a`
    text-decoration: none;
    border-radius: 6px;
    background-blend-mode: multiply;
    /* Drop Shadow Style */
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.20);
    color: var(--black, #000);
    text-align: center;
    font-weight: 400;
    width: ${props => props.width ?? '7vw'};
    padding: ${props => props.padding ?? '0.5%'};
    font-size: 1vw;
    background: white;
    color: #1F3B71;
    cursor: pointer;
    font-family: Helvetica;
    @media screen and (max-width: 800px) {
        width: auto;
        font-size: 14px;
        padding: 2%;
        padding-inline: 5%;
        margin-top: 6vh;
    }
`;

export const Head = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    font-family: HelveticaLt, Arial;

    img {
        margin-bottom: 60px;
    }

    h1 {
        font-family: inherit;
        text-transform: uppercase;
        margin-bottom: 60px;
        font-size:clamp(22px,1.5vw,35px);
    }

    a {
        width: 230px;
        color: black;
        padding: 5px 15px;
    }
`;


export const Intro  = styled.div`
    background-color: #757575;
    border-radius: 60px;
    padding: 80px 150px;
    width: 85vw;
    font-size: 25px;
    margin-bottom: 150px;
    @media screen and (max-width: 800px) {
        padding: 50px 80px;
    }
`;

export const YT = styled.div`
    width: 70vw;
    height: auto;
    margin-bottom: 150px;
    position: relative;

    &.video-playing {
        height: 500px;
    }

    @media screen and (max-width: 800px) {
        margin-bottom: 80px;
    }

    .bg-img {

    }

    .play-icon {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        filter: drop-shadow(0px 0px 30px black);
        width: 350px;
        cursor: pointer;
        transition: all .2s ease-in;

        &:hover {
            transform: translate(-50%, -50%) scale(1.1);
        }

        @media screen and (max-width: 1200px) {
            width: 300px;
        }

        @media screen and (max-width: 800px) {
            width: 200px;
        }
    }

    .iframe-holder {
        width: 100%;
        height: 100%;
    }
`;

export const DarkAgentFeatures = styled.div`
    width: 90vw;
    background-color: #757575;
    border-radius: 50px;
    padding: 100px 40px;
    margin-bottom: 200px;

    .row {
        margin-bottom: 100px;
        text-align: start;

        > div {
            padding-inline: 30px;

            &:first-of-type {
                @media screen and (max-width: 800px) {
                    margin-bottom: 80px;
                }

                img {
                    @media screen and (max-width: 1200px) {
                        width:100% !important;
                    }
                }
            }
        }

        h2 {
            font-size: 2.5rem;
            font-weight: bold;
        }

        p {
            font-size: 1.5rem;
            line-height: 40px;
        }

    }
`;

export const  DarkAgentServices = styled.div`
    width: 70vw;
    margin-bottom: 200px;

    @media screen and (max-width: 800px) {
        width: 90vw;
        margin-bottom: 80px;
    }

    .row {
        > div {
            padding-inline: 50px;


            @media screen and (max-width: 800px) {
                margin-bottom: 50px;
            }

            h2 {
                font-size: 2rem;
                margin-bottom: 50px;
            }

            p {
                font-size: 1.5rem;
            }
        }
    }
`;
